import { TenantJob, TenantPerformableConfig } from './TenantJob';
import { ZodVersionedMetadata, ZodVersionedSchema } from '~/lib/zod';
import { TenantOrder } from '~/tenants/common/TenantOrder';
import { TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import {
  TenantCustomerFormProps,
  TenantOrderFormProps,
  TenantPerformableFormProps,
  TenantPerformableSubmitProps,
} from '~/tenants/common/form';
import { ReactNode } from 'react';
import { TimeZone } from '~/lib/enum';
import { TenantCustomer, TenantProvider } from '~/tenants/common/TenantPersona';
import { GeocodedAddress, ProviderStaticConfig } from '~/lib/model';
import { Holiday } from '~common/holidays/holidays';
import TenantWrapper from '~/tenants/common/TenantWrapper';

export enum TenantId {
  BEYOND_RE_MARKETING = 'bre',
  NE_PHOTO = 'nep',
  DEMO = 'demo',
  METROPLEX_360 = 'mpx',
  MPI = 'mpi',
  PLUSH = 'pic',
  TWIST_TOURS = 'twt',
}

// used for testing purposes
export const TENANT_DEFAULT_ID = TenantId.TWIST_TOURS;

type TenantPerformableForm<O extends TenantOrderContext> = (props: TenantPerformableFormProps<O>) => ReactNode;
type TenantPerformableSubmit<O extends TenantOrderContext> = (props: TenantPerformableSubmitProps<O>) => ReactNode;

export interface TenantPerformable<
  P extends TenantPerformableConfig = TenantPerformableConfig,
  O extends TenantOrderContext = TenantOrderContext,
  V extends TenantProvider = TenantProvider,
> {
  config: TenantPerformableConfig;
  form: TenantPerformableForm<O>;
  submit?: TenantPerformableSubmit<O>;
  job: new (order: O, id?: string, metadata?: ZodVersionedMetadata<P['schema']>, provider?: V) => TenantJob<O, P>;
}

export interface TenantPackageConfig {
  id: string;
  name: string;
  description: string;
  performables: TenantPerformableConfig[];
  percentage?: number;
  eligible?: (order: TenantOrderContext) => boolean;
}

export interface TenantLocationConfig {
  slug: string;
  name: string;
  address: GeocodedAddress;
  timezone: TimeZone;
}

export interface TenantConfig {
  readonly id: TenantId;
  readonly name: string;
  readonly legal: string;
  readonly domain: string;
  readonly email: string;
  readonly phone: TPhone;
  readonly tax?: TTaxRate;
  readonly emailEnable?: boolean;
  readonly emailCustomer?: string;
  readonly emailOrderCompleteDisabled?: boolean;
  readonly nylasGrantId?: string;
  readonly nylasProviderInvite?: boolean;
  readonly userback?: boolean;
  readonly wizardProvider?: boolean;
  readonly wizardDay?: boolean;
  readonly wizardServicesTitle?: string;
  readonly holidays?: Holiday[];
  readonly markaEnable?: boolean;
  readonly inboxEnabled?: boolean;
  readonly validateRequested?: boolean;
  readonly defaultWindow?: number;
  readonly rainforestEnable?: boolean;
  readonly rainforestMerchantId?: string;
  readonly rainforestMerchantApplicationId?: string;
  readonly scheduleStartTime?: TTime24;
  readonly scheduleEndTime?: TTime24;
  readonly scheduleJobColor?: boolean;
  readonly scheduleHideCustomer?: boolean;
  readonly smsPrefix?: string;
  readonly locations: TenantLocationConfig[];
  readonly logoRaster: string;
  readonly logoVector?: string;
  readonly background?: string;
  readonly azureApplicationId?: string;
  readonly resend?: string;
  readonly orderNumberMinimum?: number;
  readonly orderNumberPadding?: number;
  readonly api?: string;
  readonly providerClass: new (tenant: TenantWrapper, id: string, data: any) => TenantProvider<any, any>;
  readonly customerClass: new (id: string, name: string, metadata: any) => TenantCustomer<any>;
  readonly orderClass: new (tenant: TenantConfig, context: TenantOrderContext<any, any, any>) => TenantOrder<any>;
  readonly forms: {
    wizardType: () => ReactNode;
    payment?: () => ReactNode;
    times?: (props: { context: TenantOrderContext<any, any, any> }) => ReactNode;
    orderConfigure: (props: TenantOrderFormProps) => ReactNode;
    provider: () => ReactNode;
    customer?: (props: TenantCustomerFormProps) => ReactNode;
    onboarding?: () => ReactNode;
  };
  readonly providers: Record<string, ProviderStaticConfig>;
  readonly orderSchema: ZodVersionedSchema;
  readonly customerSchema: ZodVersionedSchema;
  readonly packages: Array<{ config: TenantPackageConfig; form: () => ReactNode }>;
  readonly performables: Record<string, TenantPerformable<any, any, any>>;
  readonly invoiceNumberMinimum?: number;
  readonly autorespondStart?: TTime24;
  readonly autorespondEnd?: TTime24;
  readonly autorespondMessage?: string;
  readonly autorespondWeekend?: boolean;
  readonly autorespondKeywords?: Record<string, string>;
  readonly autorespondHolidays?: Partial<Record<Holiday, string>>;
}

const TENANTS = new Map<string, TenantConfig>();

export function registerTenant<Id extends TenantId, Schema extends ZodVersionedSchema>(
  id: Id,
  orderSchema: Schema,
  other: Omit<TenantConfig, 'id' | 'orderSchema'>,
): TenantConfig {
  const config = {
    id,
    orderSchema,
    ...other,
  };

  TENANTS.set(id, config);

  return config;
}

export function getTenantByApi(api: string): TenantConfig | null {
  for (const tenant of TENANTS.values()) {
    if (tenant.api === api) {
      return tenant;
    }
  }

  return null;
}

export function getTenantByDomain(domain: string): TenantConfig | null {
  for (const tenant of TENANTS.values()) {
    if (tenant.domain === domain) {
      return tenant;
    }
  }

  return null;
}

export function getTenant(id: TenantId): TenantConfig {
  const tenant = TENANTS.get(id);

  if (!tenant) {
    throw new Error(`Tenant with id "${id}" was not found.`);
  }

  return tenant;
}

export function createPerformableConfig<I extends string, S extends ZodVersionedSchema>(
  id: I,
  schema: S,
  config: Omit<TenantPerformableConfig<S, I>, 'id' | 'schema'>,
): TenantPerformableConfig<S, I> {
  return {
    id,
    schema,
    ...config,
  };
}

export function getPackage(tenant: TenantConfig, packageId: string) {
  const pkg = tenant.packages.find(({ config }) => config.id === packageId);

  if (!pkg) {
    throw new Error(`Package ${packageId} was not found.`);
  }

  return pkg;
}
